export const environment = {

  production: true,  
  api_url:'https://sgc.yadinero.co/api/',
  lottery_id: 1,
  facebook_url: 'https://www.facebook.com/yadinero',
  instagram_url: 'https://www.instagram.com/yadinero.co',
  whatsapp: 'https://wa.me/573128956522',
  mail: 'https://yadinero.co/contacto',
  lang_url:'https://sgc.yadinero.co/language/1/',
  friend_url:'https://socios.yadinero.co',
};